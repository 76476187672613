
.body {
  //--padding-start: 34px;
  //--padding-end: 34px;
  height: 100%;
}

.header {
  height: 60px;
  padding-left: 20px;
  padding-right: 10px;

  ion-toolbar {
    height: 60px;
    --background: #fff;
    --border-width: 0 !important;
    display: flex;
    align-items: center;
    .toolbar-container {
      padding-inline-start: 0 !important;
      padding-inline-end: 0 !important;
    }

    .headerTitle {
      display: flex;
      align-items: center;

      img {
        width: 36px;
        height: 36px;
        margin-left: 12px;
        margin-right: 10px;
      }

      font-family: Helvetica Neue, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #000000;

    }
  }
}


.mobileHeader {
  img {
    width: 16px;
    height: 16px;
    margin-right: 30px;
  }
}

.content {
  display: flex;
  padding: 0 30px;
  height: calc(100% - 50px);
}

.subscribersBox {
  padding-top: 30px;
  width: 100%;
  height: 100%;

  .tabs {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;

    .tabsItem {
      text-align: center;
      height: 44px;
      line-height: 44px;
      width: 50%;
      border-bottom: 1px solid #EBEBEB;
      font-family: 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #212121;
    }

    .tabsItem:hover {
      cursor: pointer;
    }

    .tabsItemChecked {
      border-bottom: 2px solid #085727;
      font-weight: 700;
    }
  }

  .pageCommonContent {
    width: 100%;
    height: calc(100% - 44px);
    padding: 0;

    .contactsList {
      height: 100%;
      width: 100%;
      --overflow: auto;

      .contactItem {
        display: flex;
        align-items: center;
        height: 40px;
        margin-top: 15px;

        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 15px;
          overflow: hidden;
        }

        .name {
          font-family: 'Helvetica Neue', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          color: #000000;
        }
      }

      .contactItem:first-child {
        margin-top: 36px;
      }

      .contactItem:hover {
        background: #fff;
        cursor: pointer;
      }
    }

    .profileSetting {
      .settingContent {
        .settingTitle {
          margin-top: 30px;
          font-family: 'Helvetica Neue', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #000000;
        }

        .list {
          .item {
            border-bottom: 1px solid #ebebeb;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            margin-top: 16px;
            .text {
            }

            ion-toggle {
              --background: #ccc;
              --background-checked: #605DEC;
              --handle-height: 22px;
              height: 25px;
              width: 47px;
              --handle-width: 22px;
            }
          }
        }
      }


      .settingFooter {
        position: absolute;
        bottom: 32px;
        height: 32px;
        width: 100%;

        .submitButton {
          position: absolute;
          right: 60px;
          width: 96px;
          height: 32px;
          background: #605DEC;
          border-radius: 44px;
          text-align: center;
          line-height: 32px;

          font-family: 'Helvetica Neue', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          color: #FFFFFF;
          cursor: pointer;

          &:hover {
            background: #3f4aee;
          }
        }
      }
    }
  }
}
